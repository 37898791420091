

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
// import { SalaryHead } from "../../models/salary-head.model";
import { SalarySlipData } from "../../../models/sub-domain/salary-head.model";
import moment from "moment";
import { SalaryStructureData } from "../../../models/sub-domain/salary-head.model";
import html2pdf from "html2pdf.js";

@Component
export default class GenerateSalarySlip extends Vue {
  public employeeList = [];
  public empData = [];
  public salaryStructureData: any = new SalaryStructureData();
  public months = Array.apply(0, Array(12)).map(function(_, i) {
    return moment()
      .month(i)
      .format("MMMM");
  });
  public years: any = [];
  public salarySlip = new SalarySlipData();
  public submitted = false;
  public source: any = null;
  public totalSalaryHeadId = null;
  public fixedSalaryHeadId = null;
  public generatedSalarySlip = false;
  public inProcess = false;
  public empUserId: any = null;
  public orglogo :any = null;
  public logo:any=null;
  public lwp_wave_off:any=null;
  public async fetchEmployeeList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/getEmployeeList",
        {
          headers: authHeader()
        }
      );
      this.employeeList = response.data ? response.data : [];
    } catch (error) {
      console.log(error);
    }
  }

  //To get the organization started on date
  public async fetchOrganizationDetails() {
    let response = await Axios.get(
      BASE_API_URL + "administrator/fetchOrganizationDetails",
      {
        headers: authHeader()
      }
    );
    let startYear = Number(moment(response.data.data.startedOn).format("YYYY"));
    let currentYear = Number(moment(new Date()).format("YYYY"));
    for (let i = startYear; i <= currentYear; i++) {
      this.years.push(i);
    }
  }

  public async searchEmployee() {
    this.submitted = true;
    let response = await Axios.post(
      BASE_API_URL + "payroll/searchEmployee",
      { ...this.salarySlip },
      {
        headers: authHeader()
      }
    );

    this.generatedSalarySlip = response.data
      ? response.data.generatedSalarySlip
      : false;

    this.empData =
      response.data && response.data.data ? response.data.data : [];
  }

  /**
   *  Get Salary Structure
   */
  public async getSalaryStructure() {
    try {
      this.empUserId = this.salarySlip.empUserId
        ? this.salarySlip.empUserId
        : this.$route.query.id;

      this.submitted = false;
      this.salaryStructureData.employeeUserId = this.empUserId;
      let response = await Axios.get(
        BASE_API_URL + "payroll/fetchSalaryStructure",
        {
          params: {
            employeeUserId: this.empUserId
          },
          headers: authHeader()
        }
      );
      this.salaryStructureData = response.data.payRollData;
      if (this.salaryStructureData && this.salaryStructureData.allSalaryHead) {
        this.salaryStructureData.allSalaryHead.forEach(element => {
          if (element.name == "Total Salary") {
            this.totalSalaryHeadId = element._id;
          }
          if (element.name == "Fixed") {
            this.fixedSalaryHeadId = element._id;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async generateSalaryStructure() {
    this.$router.push({
      path: "generate-salary-slip",
      query: {
        source: "generate-salary-structure",
        id: this.salarySlip.empUserId
      }
    });
    this.source = "generate-salary-structure";
  }

  /**
   *  Save Generated Salary Structure
   */
  public async saveGeneratedSalaryStructure() {
    this.salaryStructureData.salarySlip = this.salarySlip;

    let response = await Axios.post(
      BASE_API_URL + "payroll/saveGeneratedSalaryStructure",
      { ...this.salaryStructureData },
      {
        headers: authHeader()
      }
    );

    if (response.data) {
      this.$snotify.success(response.data.message);
      this.$router.push("payroll");
    }
  }

  public async resetData() {
    this.$router.push("generate-salary-slip");
    this.source = "";
    this.submitted = false;
    this.salarySlip.year = null;
    this.salarySlip.month = null;
    this.salarySlip.empUserId = null;
  }

  public downloadPdf() {
    var element = document.getElementById("salarySlip");
    var opt = {
      margin: 1,
      filename: "salary slip.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" }
    };

    html2pdf().set(opt).from(element).save();
    html2pdf(element, opt);

    // download pdf using hidden div
    // var source = window.document.getElementById("test").innerHTML;

    // html2pdf()
    //   .set(opt)
    //   .from(source)
    //   .save();
    // html2pdf(source);
  }

  async mounted() {
    this.source = this.$route.query.source;
    await this.fetchEmployeeList();
    await this.fetchOrganizationDetails();
    await this.getSalaryStructure();
    this.orglogo = this.$logo;
  }
}
